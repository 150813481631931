import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "col-12 text-subtitle2 text-weight-bold" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_timeline_entry = _resolveComponent("q-timeline-entry")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_timeline = _resolveComponent("q-timeline")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_form, {
      onSubmit: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveCart(_ctx.consent))),
      onValidationError: _ctx.onValidationError
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_timeline, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (room, roomIdx) => {
              return (_openBlock(), _createBlock(_component_q_timeline_entry, {
                key: roomIdx,
                title: `Номер ${roomIdx + 1}: ${
            room.addRoomResult?.roomDescription
          }`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(room.addRoomResult?.guest, (guest) => {
                        return (_openBlock(), _createBlock(_component_q_card_section, {
                          class: "row",
                          key: guest.idx
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, _toDisplayString(guest.age > -1
                    ? "Ребенок " + _ctx.formateAge(guest.age)
                    : "Взрослый " + guest.idx), 1),
                            _createElementVNode("div", _hoisted_2, " Тариф: " + _toDisplayString(guest.selectTariff?.tariffDescription), 1),
                            _createVNode(_component_q_input, {
                              class: "col-xs-12 col-sm-6 q-mr-sm",
                              modelValue: guest.FIO,
                              "onUpdate:modelValue": ($event: any) => ((guest.FIO) = $event),
                              modelModifiers: { trim: true },
                              label: "ФИО",
                              rules: [
                  (val) =>
                    (val?.length >= 3 && val.split(' ')?.length > 1) ||
                    'Введите корректные ФИО',
                ]
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_q_field, {
                                borderless: "",
                                label: "Пол",
                                modelValue: guest.SEX,
                                "onUpdate:modelValue": ($event: any) => ((guest.SEX) = $event),
                                "stack-label": "",
                                rules: [(v) => !!v || 'Пол должен быть выбран']
                              }, {
                                control: _withCtx(() => [
                                  _createVNode(_component_q_radio, {
                                    modelValue: guest.SEX,
                                    "onUpdate:modelValue": ($event: any) => ((guest.SEX) = $event),
                                    val: "M",
                                    label: "М"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_q_radio, {
                                    modelValue: guest.SEX,
                                    "onUpdate:modelValue": ($event: any) => ((guest.SEX) = $event),
                                    val: "F",
                                    label: "Ж"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "rules"])
                            ]),
                            (room?.addRoomResult?.guest[guest.idx])
                              ? (_openBlock(), _createBlock(_component_q_separator, {
                                  key: 0,
                                  class: "col-12",
                                  size: "3px"
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["title"]))
            }), 128)),
            _createVNode(_component_q_timeline_entry, { title: "Данные плательщика" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  class: "onl-bill-input q-mb-xs",
                  outlined: "",
                  ref: "fio",
                  modelValue: _ctx.customerData.customerFIO,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customerData.customerFIO) = $event)),
                  modelModifiers: { trim: true },
                  label: "Фио",
                  rules: [
              (val) =>
                (val?.length >= 3 && val.split(' ')?.length > 1) ||
                'Введите корректные ФИО',
            ]
                }, null, 8, ["modelValue", "rules"]),
                _createElementVNode("div", {
                  class: "q-mb-xs",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$refs as any).telInput.focus()))
                }, [
                  _createVNode(_component_q_field, {
                    outlined: "",
                    label: "Номер телефона",
                    modelValue: _ctx.customerData.phone,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customerData.phone) = $event)),
                    modelModifiers: { trim: true },
                    "stack-label": "",
                    rules: [
                (v) => !!v || 'Номер должен быть заполнен',
                (v) => _ctx.phoneValid || 'Введите корректный номер',
              ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_vue_tel_input, {
                        ref: "telInput",
                        class: "onl-bill-phone-input",
                        inputOptions: {
                  placeholder: 'Введите номер телефона с кодом страны',
                },
                        mode: "international",
                        modelValue: _ctx.customerData.phone,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customerData.phone) = $event)),
                        style: {"width":"100%","height":"100%","border":"none","padding-bottom":"1px"},
                        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.phoneBlurred = true)),
                        onOnInput: _cache[3] || (_cache[3] = 
          (formNumb, input) => {
            _ctx.phoneValid = input.valid;
          }
        )
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "rules"])
                ]),
                _createVNode(_component_q_input, {
                  outlined: "",
                  ref: "email",
                  class: "onl-bill-input q-mb-xs",
                  modelValue: _ctx.customerData.email,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customerData.email) = $event)),
                  modelModifiers: { trim: true },
                  label: "E-mail",
                  rules: [
              (val) =>
                (val.match(_ctx.regExpEmail) === null ? false : true) ||
                'Введите корректную почту',
            ]
                }, null, 8, ["modelValue", "rules"]),
                _createVNode(_component_q_field, {
                  class: "row items-center",
                  modelValue: _ctx.consent,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.consent) = $event)),
                  modelModifiers: { trim: true },
                  rules: [(v) => v || 'Поставьте своё согласие']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      modelValue: _ctx.consent,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.consent) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("a", {
                      class: "row items-center",
                      target: "_blank",
                      href: _ctx.config.consent_url
                    }, _toDisplayString(_ctx.config.consent_text), 9, _hoisted_3)
                  ]),
                  _: 1
                }, 8, ["modelValue", "rules"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_btn, {
                    onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.fillTestData()), ["alt","ctrl"])),
                    class: "onl-bill-btn",
                    color: "primary",
                    type: "submit"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Продолжить ")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onValidationError"])
  ]))
}