<template>
  <div>
    <q-card
      class="row justify-center q-pa-md"
      style="padding-bottom: 20px; padding-top: 10px"
      @click="
        if (step == -1) {
          dateSelectorDialog = true;
        }
      ">
      <q-input
        class="q-px-sm col-xs-12 col-sm-auto"
        readonly
        v-model="formattedSelectedDate.from"
        label="Дата заезда" />

      <q-input
        class="q-px-sm col-xs-12 col-sm-auto"
        readonly
        :model-value="formattedSelectedDate.to"
        label="Дата выезда" />

      <q-input
        class="q-px-sm col-xs-12 col-sm-auto"
        readonly
        :model-value="formattedSelectedDate.days"
        :label="
          formateDayNight(formattedSelectedDate.days).charAt(0).toUpperCase() +
          formateDayNight(formattedSelectedDate.days).slice(1) +
          ' пребывания'
        " />

      <q-input
        class="q-px-sm col-xs-12 col-sm-auto"
        readonly
        :model-value="rooms.length"
        label="Номеров" />

      <div style="margin-top: 10px" class="col-12 text-center">
        <q-btn v-if="step == -1" color="primary">Выбрать даты</q-btn>
        <q-btn
          @click="
            dateSelectorDialog = true;
            step = -1;
          "
          v-else
          color="primary"
          >Изменить даты</q-btn
        >
      </div>
    </q-card>

    <q-dialog v-model="dateSelectorDialog" class="mb-12">
      <q-card class="row justify-center q-pt-md">
        <div>
          Дата заезда
          <VueDatePicker
            v-model="selectedDateRange[0]"
            @update:model-value="changeDateFrom()"
            :format="formattedSelectedDate.from"
            :clearable="false"
            auto-apply
            :min-date="new Date()"
            :enable-time-picker="false"
            locale="ru" />
        </div>

        <div>
          Дата выезда
          <VueDatePicker
            v-model="selectedDateRange[1]"
            :format="formattedSelectedDate.to"
            :clearable="false"
            auto-apply
            :min-date="addDays(selectedDateRange[0], config.calc_night ? 1 : 0)"
            :max-date="addDays(selectedDateRange[0], config.max_days)"
            :enable-time-picker="false"
            locale="ru" />
        </div>

        <q-input
          readonly
          v-model="formattedSelectedDate.days"
          :label="
            formateDayNight(formattedSelectedDate.days)
              .charAt(0)
              .toUpperCase() +
            formateDayNight(formattedSelectedDate.days).slice(1) +
            ' пребывания'
          " />

        <VueDatePicker
          style="dp-background-color: #007748"
          v-if="!small"
          class="col-auto"
          multi-calendars
          v-model="selectedDateRange"
          inline
          auto-apply
          range
          :partial-range="false"
          :min-range="config.calc_night ? 1 : 0"
          :max-range="config.max_days"
          :min-date="new Date()"
          :enable-time-picker="false"
          :month-change-on-scroll="false"
          locale="ru"></VueDatePicker>

        <!-- номер -->
        <transition-group name="rooms" tag="r">
          <q-card
            class="rooms-item"
            :id="'room-' + roomIdx"
            bordered
            style="margin: 10px"
            v-for="(room, roomIdx) in rooms"
            :key="roomIdx">
            <q-card-section class="row justify-center">
              <div
                style="font-size: 15px; margin-bottom: 5px"
                class="text-center">
                Номер {{ roomIdx + 1 }}
              </div>

              <div class="col-12 text-center">Проживающие</div>

              <!-- иконки -->

              <div class="col-auto">
                <transition-group name="icons" tag="p">
                  <!-- иконка взрослого -->
                  <div
                    @click="if (room.search.adults > 1) room.search.adults--;"
                    class="icons-item"
                    v-for="adult in room.search.adults"
                    :key="'a' + adult"
                    style="font-size: 40px">
                    <div :class="{ 'icon-container': room.search.adults > 1 }">
                      <q-icon
                        style="color: rgba(255, 0, 0, 0.664)"
                        class="icon-adult-up"
                        name="fa-solid fa-times" />
                      <q-icon class="icon-down" name="fa-solid fa-person" />
                    </div>
                  </div>
                  <!-- иконка ребенка -->
                  <div
                    @click="room.search.children.splice(i, 1)"
                    class="text-center icons-item"
                    v-for="(child, i) in room.search.children"
                    :key="child">
                    <div class="icon-container">
                      <q-icon
                        style="font-size: 20px; color: rgba(255, 0, 0, 0.664)"
                        class="icon-child-up"
                        name="fa-solid fa-times" />

                      <div class="icon-down">
                        <div>{{ child }}</div>
                        <q-icon
                          style="font-size: 25px"
                          name="fa-solid fa-child" />
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>

              <div class="col-12 row justify-between">
                <q-btn
                  style="font-size: 11px"
                  class="col-5"
                  @click="room.search.adults++">
                  Добавить взрослого ({{ config.max_child_age + 1 }}+)
                </q-btn>

                <q-btn
                  style="font-size: 11px"
                  class="col-5"
                  label="Добавить ребенка">
                  <q-menu fit anchor="bottom left" self="top left">
                    <q-item
                      v-close-popup
                      @click="
                        room.search.children.push(i + config.min_child_age)
                      "
                      clickable
                      v-for="(n, i) in config.max_child_age -
                      config.min_child_age +
                      1"
                      :key="i">
                      <q-item-section
                        >Ребенок
                        {{ formateAge(i + config.min_child_age) }}
                      </q-item-section>
                    </q-item>
                  </q-menu>
                </q-btn>
              </div>

              <q-btn
                style="position: absolute; top: 10px; right: 10px"
                size="sm"
                flat
                rounded
                v-if="rooms.length > 1"
                @click="rooms.splice(roomIdx, 1)">
                Удалить номер
              </q-btn>
            </q-card-section>
          </q-card>
        </transition-group>
        <!--  -->
        <q-btn
          size="sm"
          flat
          style="margin-bottom: 10px"
          @click="addRoomForSearch()">
          Добавить еще один номер
        </q-btn>

        <div class="col-12 q-mb-md q-px-md">
          <q-btn style="width: 100%" color="primary" @click.once="findRooms()"
            >Найти номера</q-btn
          >
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapWritableState, mapState, mapActions } from "pinia";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import VueScrollTo from "vue-scrollto";

//stores
import { useSearchStore } from "@/stores/searchStore";
import { useSessionStore } from "@/stores/sessionStore";

import moment from "moment";

export default defineComponent({
  components: { VueDatePicker },
  data: () => ({ small: false }),

  computed: {
    ...mapState(useSearchStore, ["formattedSelectedDate"]),
    ...mapWritableState(useSearchStore, [
      "rooms",
      "dateSelectorDialog",
      "step",
      "selectedDateRange",
    ]),

    ...mapState(useSessionStore, ["config"]),

    minDate() {
      return moment().subtract(1, "day").toDate();
    },
  },

  methods: {
    ...mapActions(useSearchStore, [
      "findRooms",
      "formateAge",
      "formateDayNight",
    ]),

    addRoomForSearch() {
      this.rooms.push({ search: { adults: 1, children: [] } });

      const room = document.querySelector("#room-1")?.scrollTop;

      if (room) {
        VueScrollTo.scrollTo("#room-1", 1000, { offset: -20 });
      }
    },

    addDays(date: Date, days: number) {
      return moment(date).add(days, "days").toDate();
    },

    changeDateFrom() {
      const dateFrom = moment(this.selectedDateRange[0]).startOf("day");
      const dateTo = moment(this.selectedDateRange[1]).startOf("day");

      if (!dateFrom.isBefore(dateTo)) {
        this.selectedDateRange[1] = dateFrom.clone().add(1, "day").toDate();
      }

      if (
        dateTo.diff(
          dateFrom.clone().add(this.config.max_days).startOf("day"),
          "days"
        ) > this.config.max_days
      ) {
        this.selectedDateRange[1] = dateFrom
          .clone()
          .add(this.config.max_days, "days")
          .toDate();
      }
    },

    onResize() {
      this.small = window.innerWidth <= 600;
    },
  },

  created() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
});
</script>

<style>
.dp__theme_light {
  --dp-primary-color: var(--q-primary);
}
</style>

<!-- Аниимация иконок -->
<style>
/* .list-item:hover {
  box-shadow: inset 0 0 1px 1px rgb(255, 0, 0);
} */
.icons-item {
  transition: all 0.8s ease;
  display: inline-block;
}
.icons-enter-from,
.icons-leave-to {
  opacity: 0;
}
.icons-leave-active {
  position: absolute;
}
</style>

<!-- Анпимация наведения на иконку -->
<style>
.icon-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}
.icon-container:hover .icon-adult-up {
  opacity: 1;
}
.icon-container:hover .icon-child-up {
  opacity: 1;
}

.icon-adult-up {
  position: absolute !important;
  top: 20%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  z-index: 9999;
}

.icon-child-up {
  position: absolute !important;
  top: 50%;
  bottom: 0%;
  left: 10%;
  right: 0%;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  z-index: 9999;
}
</style>

<style>
/* .list-item:hover {
  box-shadow: inset 0 0 1px 1px rgb(255, 0, 0);
} */
.rooms-item {
  transition: all 0.8s ease;
  display: inline-block;
}
.rooms-enter-from,
.rooms-leave-to {
  opacity: 0;
}
.rooms-leave-active {
  position: absolute;
}
</style>

<style>
.dp__menu_inner .dp__instance_calendar:first-child {
  border-right: solid 2px;
  padding-right: 2px;
}

.dp__menu_inner .dp__instance_calendar:nth-of-type(2) {
  padding-left: 2px;
}
</style>
