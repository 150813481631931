<template>
  <div>
    <q-form @submit="saveCart(consent)" @validation-error="onValidationError">
      <q-timeline>
        <q-timeline-entry
          v-for="(room, roomIdx) in rooms"
          :key="roomIdx"
          :title="`Номер ${roomIdx + 1}: ${
            room.addRoomResult?.roomDescription
          }`">
          <q-card>
            <q-card-section
              class="row"
              v-for="guest in room.addRoomResult?.guest"
              :key="guest.idx">
              <div class="col-12 text-subtitle2 text-weight-bold">
                {{
                  guest.age > -1
                    ? "Ребенок " + formateAge(guest.age)
                    : "Взрослый " + guest.idx
                }}
              </div>
              <div class="col-12">
                Тариф: {{ guest.selectTariff?.tariffDescription }}
              </div>
              <q-input
                class="col-xs-12 col-sm-6 q-mr-sm"
                v-model.trim="guest.FIO"
                label="ФИО"
                :rules="[
                  (val) =>
                    (val?.length >= 3 && val.split(' ')?.length > 1) ||
                    'Введите корректные ФИО',
                ]"></q-input>

              <div>
                <q-field
                  borderless
                  label="Пол"
                  v-model="guest.SEX"
                  stack-label
                  :rules="[(v) => !!v || 'Пол должен быть выбран']">
                  <template v-slot:control>
                    <q-radio v-model="guest.SEX" val="M" label="М" />
                    <q-radio v-model="guest.SEX" val="F" label="Ж" />
                  </template>
                </q-field>
              </div>

              <q-separator
                v-if="room?.addRoomResult?.guest[guest.idx]"
                class="col-12"
                size="3px" />
            </q-card-section>
          </q-card>
        </q-timeline-entry>

        <!--  -->

        <q-timeline-entry title="Данные плательщика">
          <q-input
            class="onl-bill-input q-mb-xs"
            outlined
            ref="fio"
            v-model.trim="customerData.customerFIO"
            label="Фио"
            :rules="[
              (val) =>
                (val?.length >= 3 && val.split(' ')?.length > 1) ||
                'Введите корректные ФИО',
            ]"></q-input>

          <div class="q-mb-xs" @click="($refs as any).telInput.focus()">
            <q-field
              outlined
              label="Номер телефона"
              v-model.trim="customerData.phone"
              stack-label
              :rules="[
                (v) => !!v || 'Номер должен быть заполнен',
                (v) => phoneValid || 'Введите корректный номер',
              ]">
              <vue-tel-input
                ref="telInput"
                class="onl-bill-phone-input"
                :inputOptions="{
                  placeholder: 'Введите номер телефона с кодом страны',
                }"
                mode="international"
                v-model="customerData.phone"
                style="
                  width: 100%;
                  height: 100%;
                  border: none;
                  padding-bottom: 1px;
                "
                @blur="phoneBlurred = true"
                @on-input="
          (formNumb: string, input: any) => {
            phoneValid = input.valid;
          }
        "></vue-tel-input>
            </q-field>
          </div>

          <q-input
            outlined
            ref="email"
            class="onl-bill-input q-mb-xs"
            v-model.trim="customerData.email"
            label="E-mail"
            :rules="[
              (val) =>
                (val.match(regExpEmail) === null ? false : true) ||
                'Введите корректную почту',
            ]"></q-input>

          <q-field
            class="row items-center"
            v-model.trim="consent"
            :rules="[(v) => v || 'Поставьте своё согласие']">
            <q-checkbox v-model="consent" />
            <a
              class="row items-center"
              target="_blank"
              :href="config.consent_url"
              >{{ config.consent_text }}</a
            >
          </q-field>

          <div class="row justify-center">
            <q-btn
              @click.alt.ctrl="fillTestData()"
              class="onl-bill-btn"
              color="primary"
              type="submit">
              Продолжить
            </q-btn>
          </div>
        </q-timeline-entry>
      </q-timeline>
    </q-form>
  </div>
</template>

<script lang="ts">
import { useSearchStore } from "@/stores/searchStore";
import { useSessionStore } from "@/stores/sessionStore";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import VueScrollTo from "vue-scrollto";

export default defineComponent({
  computed: {
    ...mapState(useSearchStore, ["rooms", "customerData"]),
    ...mapState(useSessionStore, ["config"]),
  },

  methods: {
    ...mapActions(useSearchStore, ["saveCart", "formateAge"]),

    // eslint-disable-next-line
    onValidationError(ref: any) {
      const el = ref.$el;

      if (el) {
        VueScrollTo.scrollTo(el, 1000, { offset: -20 });
        return;
      }
    },

    fillTestData() {
      for (const room of this.rooms) {
        if (room.addRoomResult) {
          for (const guest of room.addRoomResult.guest) {
            guest.FIO = "Фамилия Имя Отчество" + guest.idx;
            guest.SEX = "M";
          }
        }
      }

      this.customerData.email = "test@mail.ru";
      this.customerData.customerFIO = "Фамилия Имя Отчество";
      this.customerData.phone = "+79281234567";
    },
  },

  data: () => ({
    phoneValid: true,
    phoneBlurred: false,
    regExpEmail:
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
    consent: false,
  }),
});
</script>

<style>
.vue-tel-input:focus-within {
  box-shadow: none !important;
}
.vti__dropdown {
  padding-left: 0 !important;
}
.vti__flag {
  margin-left: 0 !important;
}
</style>
